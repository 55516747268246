import asLazyPage from 'hocs/asLazyPage';

const Login = asLazyPage(() => import('pages/login'));

const Registration = asLazyPage(() => import('pages/registration'));

const ForgotPassword = asLazyPage(() => import('pages/forgotPassword'));

const ResetPassword = asLazyPage(() => import('pages/resetPassword'));

const Home = asLazyPage(() => import('pages/home'));

const ProcessDefinitionCreateForm = asLazyPage(
  () => import('pages/processDefinitionCreateForm')
);

const ProcessDefinitionCreateSecurityGroup = asLazyPage(
  () => import('pages/processDefinitionCreateSecurityGroup')
);

const ProcessDefinitionSecurityGroupDetails = asLazyPage(
  () => import('pages/processDefinitionSecurityGroupDetails')
);

const ProcessEditor = asLazyPage(() => import('pages/processEditor'));

const ProcessEditorFields = asLazyPage(
  () => import('pages/processEditorFields')
);

const ProcessEditorForms = asLazyPage(() => import('pages/processEditorForms'));

const ProcessEditorFormDetails = asLazyPage(
  () => import('pages/processEditorFormDetails')
);

const ProcessEditorFormManage = asLazyPage(
  () => import('pages/processEditorFormManage')
);

const ProcessDefinition = asLazyPage(() => import('pages/processDefinition'));

const ProcessDefinitionDetails = asLazyPage(
  () => import('pages/processDefinitionDetails')
);

const ProcessDefinitionVersions = asLazyPage(
  () => import('pages/processDefinitionVersions')
);

const ProcessDefinitionSecurity = asLazyPage(
  () => import('pages/processDefinitionSecurity')
);

const ProcessDefinitionDataModelFields = asLazyPage(
  () => import('pages/processDefinitionDataModelFields')
);

const ProcessDefinitionVersionFields = asLazyPage(
  () => import('pages/processDefinitionVersionFields')
);

const ProcessDefinitionForms = asLazyPage(
  () => import('pages/processDefinitionForms')
);

const ProcessDefinitionFormDetails = asLazyPage(
  () => import('pages/processDefinitionFormDetails')
);

const ProcessDefinitionScanning = asLazyPage(
  () => import('pages/processDefinitionScanning')
);

const ProcessManagement = asLazyPage(() => import('pages/processManagement'));

const ProcessManagementDetails = asLazyPage(
  () => import('pages/processManagementDetails')
);

const ProcessExecutionList = asLazyPage(
  () => import('pages/processExecutionList')
);

const ProcessInstance = asLazyPage(() => import('pages/processInstance'));

const ProcessInstanceEditor = asLazyPage(
  () => import('pages/processInstanceEditor')
);

const ProcessDefinitionScanningDetails = asLazyPage(
  () => import('pages/processDefinitionScanningDetails')
);

const ProcessManagementScanningScenarioOld = asLazyPage(
  () => import('pages/processManagementScanningScenarioOld')
);

const ProcessManagementScanningScenario = asLazyPage(
  () => import('pages/processManagementScanningScenario')
);

const ProcessDefinitionPrintRender = asLazyPage(
  () => import('pages/processDefinitionPrintRender')
);

const Management = asLazyPage(() => import('pages/management'));

const ManagementUsers = asLazyPage(() => import('pages/managementUsers'));

const ManagementInvitations = asLazyPage(
  () => import('pages/managementInvitations')
);

const ManagementUserLogs = asLazyPage(() => import('pages/managementUserLogs'));

const ManagementTenants = asLazyPage(() => import('pages/managementTenants'));

const ManagementEmailSettings = asLazyPage(
  () => import('pages/managementEmailSettings')
);

const ManagementConnections = asLazyPage(
  () => import('pages/managementConnections')
);

const ManagementConnectionsDetails = asLazyPage(
  () => import('pages/managementConnectionsDetails')
);

const PrintRenderDetails = asLazyPage(() => import('pages/printRenderDetails'));

export const PrintRenderDetailsOverrideConfig = asLazyPage(
  () => import('pages/printRenderDetailsOverrideConfig')
);

const PrintRenderResultDetailsPrintFile = asLazyPage(
  () => import('pages/printRenderResultDetailsPrintFile')
);

const ExternalForm = asLazyPage(() => import('pages/externalForm'));

const ExternalFormSubmitted = asLazyPage(
  () => import('pages/externalFormSubmitted')
);

const EmailPolling = asLazyPage(() => import('pages/emailPolling'));

const EmailPollingDetails = asLazyPage(
  () => import('pages/emailPollingDetails')
);

const Ocr = asLazyPage(() => import('pages/ocr'));

const OcrTest = asLazyPage(() => import('pages/ocrTest'));

const ProcessDefinitionEmailTemplates = asLazyPage(
  () => import('pages/processDefinitionEmailTemplates')
);

const ProcessDefinitionIndexingSource = asLazyPage(
  () => import('pages/processDefinitionIndexingSource')
);

const ProcessDefinitionEmailTemplatesDetails = asLazyPage(
  () => import('pages/processDefinitionEmailTemplatesDetails')
);

const SplitDocuments = asLazyPage(() => import('pages/splitDocuments'));

const SystemLogs = asLazyPage(() => import('pages/systemLogs'));

const ProcessEditorEmailTemplates = asLazyPage(
  () => import('pages/processEditorEmailTemplates')
);

const ProcessEditorEmailTemplatesDetails = asLazyPage(
  () => import('pages/processEditorEmailTemplatesDetails')
);

const FtpPolling = asLazyPage(() => import('pages/ftpPolling'));

const FtpPollingDetails = asLazyPage(() => import('pages/ftpPollingDetails'));

const ProcessExecutionStatus = asLazyPage(
  () => import('pages/processExecutionStatus')
);

export const ProcessInstanceCreation = asLazyPage(
  () => import('pages/processInstanceCreation')
);

export {
  Login,
  Registration,
  ForgotPassword,
  ResetPassword,
  Home,
  ProcessDefinitionCreateForm,
  ProcessEditor,
  ProcessDefinition,
  ProcessDefinitionDetails,
  ProcessDefinitionVersionFields,
  ProcessDefinitionDataModelFields,
  ProcessDefinitionForms,
  ProcessDefinitionFormDetails,
  ProcessDefinitionScanning,
  ProcessManagement,
  ProcessManagementDetails,
  ProcessExecutionList,
  ProcessDefinitionScanningDetails,
  ProcessManagementScanningScenario,
  ProcessManagementScanningScenarioOld,
  ProcessInstance,
  ProcessInstanceEditor,
  ProcessDefinitionPrintRender,
  PrintRenderDetails,
  ProcessDefinitionVersions,
  ExternalForm,
  ExternalFormSubmitted,
  PrintRenderResultDetailsPrintFile,
  EmailPolling,
  EmailPollingDetails,
  Ocr,
  OcrTest,
  SplitDocuments,
  ProcessDefinitionSecurity,
  ProcessDefinitionCreateSecurityGroup,
  ProcessDefinitionSecurityGroupDetails,
  ProcessDefinitionEmailTemplates,
  ProcessDefinitionEmailTemplatesDetails,
  ProcessDefinitionIndexingSource,
  SystemLogs,
  ProcessEditorFields,
  ProcessEditorForms,
  ProcessEditorFormDetails,
  ProcessEditorFormManage,
  ProcessEditorEmailTemplates,
  ProcessEditorEmailTemplatesDetails,
  Management,
  ManagementUsers,
  ManagementEmailSettings,
  ManagementTenants,
  ManagementConnections,
  ManagementConnectionsDetails,
  ManagementInvitations,
  FtpPolling,
  FtpPollingDetails,
  ProcessExecutionStatus,
  ManagementUserLogs,
};
