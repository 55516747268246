import {
  SearchEntity,
  SearchField,
} from 'components/organisms/form/search/types';

import { DataModelFieldType } from 'constants/global';
import { MASKS } from 'constants/masks';

import { string } from 'utils/normalizers';
import { isRequired } from 'utils/validators';

import { FormFields } from './types';

const TEXTS = {
  LABEL: 'Select Field to Search',
  FIELDS: {
    FIELD: { label: 'Field', placeholder: 'Fields' },
    FUNCTION: { label: 'Function', placeholder: 'Functions' },
    VALUE: 'Value',
  },
  ADD_FIELD: 'Add a field',
  REJECT: 'Reset',
  SUBMIT: 'Apply',
  SAVE_BTN: 'Save to My Tasks',
  CANCEL: 'Cancel',
  SAVE_SEARCH_TEMPLATE_MODAL: {
    LABEL: 'Save Template to My Tasks',
    TOAST_MSG: 'Templated was saved to My Tasks',
  },
};

const FIELDS_NAMES = {
  FIELDS: 'fields',
};

const FIELDS = {
  OPERATOR: {
    name: 'operator',
  },
  ARRAY: {
    name: 'fieldFunctionValueInput',
  },
  FIELD: {
    name: 'field',
    label: TEXTS.FIELDS.FIELD.label,
    placeholder: TEXTS.FIELDS.FIELD.placeholder,
    validate: isRequired,
  },
  FUNCTION: {
    name: 'function',
    label: TEXTS.FIELDS.FUNCTION.label,
    placeholder: TEXTS.FIELDS.FUNCTION.placeholder,
    validate: isRequired,
  },
  VALUE: {
    name: 'value',
    label: TEXTS.FIELDS.VALUE,
    placeholder: TEXTS.FIELDS.VALUE,
  },
  NUMBER_VALUE: {
    placeholder: '0',
    normalize: string.getOnlyNumbers,
    format: string.getOnlyNumbers,
  },
  DATE_VALUE: {
    name: 'value',
    label: TEXTS.FIELDS.VALUE,
    mask: MASKS[DataModelFieldType.Date],
    placeholder: 'mm/dd/yyyy',
  },
};

const NUMBER_FUNCTIONS = {
  equal: {
    label: 'Equal',
    value: 'equal',
    type: 'number',
    config: {
      operation: 'equal',
    },
  },
  equalNot: {
    label: 'Not equal',
    value: 'equalNot',
    type: 'number',
    config: {
      operation: 'equal',
      not: true,
    },
  },
  greaterThan: {
    label: 'Greater than',
    value: 'greaterThan',
    type: 'number',
    config: {
      operation: 'greater_than',
    },
  },
  lessThan: {
    label: 'Less than',
    value: 'lessThan',
    type: 'number',
    config: {
      operation: 'less_than',
    },
  },
  gte: {
    label: 'Greater than or equal',
    value: 'gte',
    type: 'number',
    config: {
      operation: 'gte',
    },
  },
  lte: {
    label: 'Less than or equal',
    value: 'lte',
    type: 'number',
    config: {
      operation: 'lte',
    },
  },
  isNull: {
    label: 'Is Null',
    value: 'isNull',
    type: 'number',
    config: {
      operation: 'is_null',
    },
  },
  isNullNot: {
    label: 'Is not Null',
    value: 'isNullNot',
    type: 'number',
    config: {
      operation: 'is_null',
      not: true,
    },
  },
} as const;

const STRING_FUNCTIONS = {
  startsWith: {
    label: 'Start with',
    value: 'startsWith',
    type: 'string',
    config: {
      operation: 'starts_with',
    },
  },
  endWith: {
    label: 'End with',
    value: 'endWith',
    type: 'string',
    config: {
      operation: 'end_with',
    },
  },
  contains: {
    label: 'Contains',
    value: 'contains',
    type: 'string',
    config: {
      operation: 'contains',
    },
  },
  equal: {
    label: 'Equal',
    value: 'equal',
    type: 'string',
    config: {
      operation: 'equal',
    },
  },
  equalNot: {
    label: 'Not equal',
    value: 'equalNot',
    type: 'string',
    config: {
      operation: 'equal',
      not: true,
    },
  },
  isEmpty: {
    label: 'Is empty',
    value: 'isEmpty',
    type: 'string',
    config: {
      operation: 'is_empty',
    },
  },
  isEmptyNot: {
    label: 'Is not empty',
    value: 'isEmptyNot',
    type: 'string',
    config: {
      operation: 'is_empty',
      not: true,
    },
  },
  isNull: {
    label: 'Is Null',
    value: 'isNull',
    type: 'string',
    config: {
      operation: 'is_null',
    },
  },
  isNullNot: {
    label: 'Is not Null',
    value: 'isNullNot',
    type: 'string',
    config: {
      operation: 'is_null',
      not: true,
    },
  },
} as const;

const OPERATOR_STATES = {
  left: { label: 'AND', value: 'AND' },
  right: { label: 'OR', value: 'OR' },
};

const getPairs = (sections: FormFields['fields'], part = 'field') =>
  Object.entries(sections ?? {}).reduce(
    (acc, [section, value]) => ({
      ...acc,
      ...(value && value[part as keyof SearchEntity]
        ? {
            [section]: (value[part as keyof SearchEntity] as SearchField).value,
          }
        : {}),
    }),
    {}
  );

export {
  FIELDS,
  TEXTS,
  NUMBER_FUNCTIONS,
  STRING_FUNCTIONS,
  OPERATOR_STATES,
  getPairs,
  FIELDS_NAMES,
};
