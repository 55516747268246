import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  FieldGeneral,
  GetDMFsByTypeResult,
  GetFieldsByIdResult,
} from 'api/endpoints/processFields';

import { FIELDS_ACTION_TYPES, SEARCH_FIELDS_META_TITLE } from './actions';

export interface FieldState {
  list: Nullable<FieldGeneral[]>;
  total: Nullable<number>;
  uniqueField: KeyValuePairs<Nullable<FieldGeneral | FieldGeneral[]>>;
  qSField: Nullable<FieldGeneral>;
  fieldDetails: Nullable<FieldGeneral>;
  reFetchFieldsTrigger: number;
  listByType: KeyValuePairs<Nullable<FieldGeneral[]>>;
}

const field = combineReducers<FieldState>({
  list(
    state = null,
    { type, payload }: { type: string; payload: GetFieldsByIdResult }
  ) {
    switch (type) {
      case FIELDS_ACTION_TYPES.SET_PROCESS_FIELDS:
        return payload ? payload.data : null;
      default:
        return state;
    }
  },
  total(
    state = null,
    { type, payload }: { type: string; payload: GetFieldsByIdResult }
  ) {
    switch (type) {
      case FIELDS_ACTION_TYPES.SET_PROCESS_FIELDS:
        return payload ? payload.total : null;
      default:
        return state;
    }
  },
  uniqueField(
    state = {},
    {
      type,
      payload,
    }: {
      type: string;
      payload: KeyValuePairs<Nullable<FieldGeneral>>;
    }
  ) {
    switch (type) {
      case FIELDS_ACTION_TYPES.UPDATE_UNIQUE_FIELDS:
        return payload;
      default:
        return state;
    }
  },
  qSField(
    state = null,
    {
      type,
      payload,
      meta,
    }: {
      type: string;
      payload: GetDMFsByTypeResult;
      meta: { title: string };
    }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_ID.START:
        return meta?.title === SEARCH_FIELDS_META_TITLE.Q_SEARCH ? null : state;
      case API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_ID.SUCCESS:
        return meta?.title === SEARCH_FIELDS_META_TITLE.A_SEARCH &&
          payload?.data
          ? payload?.data[0]
          : state;
      default:
        return state;
    }
  },
  fieldDetails(
    state = null,
    {
      type,
      payload,
    }: {
      type: string;
      payload: Nullable<FieldGeneral>;
    }
  ) {
    switch (type) {
      case FIELDS_ACTION_TYPES.SET_FIELD_DETAILS:
        return payload ?? null;
      default:
        return state;
    }
  },
  reFetchFieldsTrigger(state = 0, { type }: ReduxAction) {
    switch (type) {
      case FIELDS_ACTION_TYPES.TRIGGER_RE_FETCH_FIELDS:
        return state + 1;
      default:
        return state;
    }
  },
  listByType(
    state = {},
    {
      type,
      payload,
      meta,
    }: ReduxAction<GetDMFsByTypeResult, { input: { type: string } }>
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_TYPE_ID.SUCCESS:
        return {
          ...state,
          [meta.input.type]: payload.data,
        };
      default:
        return state;
    }
  },
});

export default field;
