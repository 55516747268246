import { Mapper } from 'types/dataPullBack';
import { ReadInputBarcodeFormat } from 'zxing-wasm';

import {
  transformRequest,
  transformResponse,
} from 'api/apiReqSagaCreator/httpRequest';
import { ProcessInstanceDetails } from 'api/endpoints/processInstance';
import {
  WithFieldId,
  WithName,
  WithProcessInstanceId,
  WithReleaseOrDraftProcessId,
  WithScanConfigurationId,
  WithScanDocContainerId,
  WithScanInstanceId,
} from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import {
  ApiPaths,
  CONTENT_TYPE,
  HTTP_METHODS,
  RESPONSE_TYPE,
} from '../constants';

const { REACT_APP_API_DOCUMENT_SERVICE: documentServiceUrl } = process.env;

export type DocumentFormData = DynamicFormNormalizedParams;

export interface CreateDocument {
  barcode?: string | null;
  formData?: DocumentFormData;
  pages?: string[];
}

export interface CreateDocumentArgs {
  data: CreateDocument;
}

export interface FieldFileParams {
  name: string;
}

export interface UpdatePage {
  page: FormData;
  updateDocMeta?: {
    localPageName: string;
    docId: string;
  };
}

export type Resolutions = 100 | 150 | 200 | 300;

export type ScanStrategyType =
  | 'manual'
  | 'barcodePerPage'
  | 'barcodePerDocument';

export type ScanBarcodeType =
  | 'code39'
  | 'code128'
  | 'codabar'
  | 'interleaved2of5';

export const BarcodeTypeZXingMap: Record<
  ScanBarcodeType,
  ReadInputBarcodeFormat
> = {
  codabar: 'Codabar',
  code39: 'Code39',
  code128: 'Code128',
  interleaved2of5: 'ITF',
};

export type ScanPixelType = 'bw' | 'gray' | 'color';

export interface ScanStrategy {
  type: ScanStrategyType;
  fieldId: string;
  barcodeType?: ScanBarcodeType;
  indexingSourceId?: string;
}

export interface ScanConfigurationGeneral extends BaseEntity {
  allowOverwrite: boolean;
  autoFeeder: boolean;
  duplex: boolean;
  formId: string;
  pixelType: ScanPixelType;
  resolution: Resolutions;
  scannedDocumentFieldId: string;
  predefinedFieldId?: string;
  predefinedFieldText?: string;
  showUi: boolean;
  strategy: ScanStrategy;
  mapper?: Mapper;
}

export interface ScanDocumentContainer extends BaseEntity {
  scanInstanceId: string;
  formData?: DocumentFormData;
  pages?: string[] | null;
  barcode?: string;
}

export interface UpdateScanDoc {
  data: {
    scanInstanceId?: string;
    formData?: DocumentFormData;
    pages?: string[];
    barcode?: string;
  };
}

export interface UpdateScanConfig {
  data: Omit<
    ScanConfigurationGeneral,
    'id' | 'name' | 'createdAt' | 'updatedAt'
  >;
}

export interface BulkUpdateDoc {
  data: {
    scanDocuments: UpdateScanDoc['data'][];
  };
}

export interface DeleteScanDocPage {
  pages: {
    names: string[];
  };
  afterDocDelete?: boolean;
}

export interface CreateScanInstance {
  scanConfigurationId: string;
}

export interface CreateScanInstanceArgs {
  data: CreateScanInstance;
}

export interface SaveScannedPage {
  data: FormData;
}

export interface UploadFile {
  field: FormData;
}

export interface UpdateDoc {
  data: Partial<ScanDocumentContainer>;
}

export interface GetAllDocumentsByName {
  name?: string;
}

export interface PagesNames {
  names: string[];
}

export interface GetPagesPreview {
  data: PagesNames;
}

export interface TransformScanPage {
  data: {
    rotate: {
      angle: number;
    };
  };
}

export interface ScanInstanceGeneral extends Omit<BaseEntity, 'name'> {
  scanConfigurationId: string;
}

export type DeleteScanDocumentParams = WithReleaseOrDraftProcessId &
  WithScanInstanceId &
  WithScanDocContainerId;
export type DeleteScanDocumentResult = unknown;
export type UpdateScanDocumentContainerParams = UpdateScanDoc &
  WithReleaseOrDraftProcessId &
  WithScanInstanceId &
  WithScanDocContainerId;
export type UpdateScanDocumentContainerResult = ScanDocumentContainer;
export type UpdateScanConfigurationParams = UpdateScanConfig &
  WithReleaseOrDraftProcessId &
  WithScanConfigurationId;
export type UpdateScanConfigurationResult = ScanConfigurationGeneral;
export type DeleteScanConfigurationParams = WithReleaseOrDraftProcessId &
  WithScanConfigurationId;
export type DeleteScanConfigurationResult = unknown;
export type BulkUpdateDocumentParams = BulkUpdateDoc &
  WithReleaseOrDraftProcessId &
  WithScanInstanceId;
export type BulkUpdateDocumentResult = unknown;
export type DeleteScanDocumentPageParams = WithReleaseOrDraftProcessId &
  WithScanInstanceId &
  WithName;
export type DeleteScanDocumentPageResult = unknown;
export type DeleteScanDocumentPagesParams = WithReleaseOrDraftProcessId &
  WithScanInstanceId &
  DeleteScanDocPage;
export type DeleteScanDocumentPagesResult = unknown;
export type CreateScanConfigurationParams = WithReleaseOrDraftProcessId &
  UpdateScanConfig;
export type CreateScanConfigurationResult = ScanConfigurationGeneral;
export type GetAllScanConfigurationsParams = Pagination &
  WithReleaseOrDraftProcessId;
export type GetAllScanConfigurationsResult =
  DataWithTotal<ScanConfigurationGeneral>;
export type GetScanConfigurationByIdParams = WithReleaseOrDraftProcessId &
  WithScanConfigurationId;
export type GetScanConfigurationByIdResult = ScanConfigurationGeneral &
  WithHypermedia;
export type CreateScanningInstanceParams = WithReleaseOrDraftProcessId &
  CreateScanInstanceArgs;
export type CreateScanningInstanceResult = ScanInstanceGeneral & WithHypermedia;
export type SaveScannedPageParams = SaveScannedPage &
  WithReleaseOrDraftProcessId &
  WithScanInstanceId;
export type SaveScannedPageResult = unknown;
export type UploadFileWhileExecuteParams = UploadFile &
  WithProcessInstanceId &
  WithFieldId;
export type UploadFileWhileExecuteResult = WithHypermedia & { url: string };
export type GetUploadedFileWhileExecuteParams = WithProcessInstanceId &
  WithFieldId &
  WithName;
export type GetUploadedFileWhileExecuteResult = unknown;
export type GetUploadedFilePreviewWhileExecuteParams = WithProcessInstanceId &
  WithFieldId &
  WithName;
export type GetUploadedFilePreviewWhileExecuteResult = unknown;
export type CreateDocumentParams = CreateDocumentArgs &
  WithReleaseOrDraftProcessId &
  WithScanInstanceId;
export type CreateDocumentResult = ScanDocumentContainer;
export type UpdateDocumentParams = UpdateDoc &
  WithReleaseOrDraftProcessId &
  WithScanInstanceId &
  WithScanDocContainerId;
export type UpdateDocumentResult = ScanDocumentContainer;
export type GetDocumentByIdParams = WithReleaseOrDraftProcessId &
  WithScanInstanceId &
  WithScanDocContainerId;
export type GetDocumentByIdResult = ScanDocumentContainer;
export type GetListDocumentsParams = Pagination &
  WithReleaseOrDraftProcessId &
  WithScanInstanceId;
export type GetListDocumentsResult = DataWithTotal<ScanDocumentContainer>;
export type GetAllDocumentsByNameParams = Pagination &
  WithReleaseOrDraftProcessId &
  WithScanInstanceId &
  GetAllDocumentsByName;
export type GetAllDocumentsByNameResult = DataWithTotal<ScanDocumentContainer>;
export type GetPagesPreviewParams = GetPagesPreview &
  WithReleaseOrDraftProcessId &
  WithScanInstanceId;
export type GetPagesPreviewResult = ArrayBuffer;
export type TransformScanPageParams = TransformScanPage &
  WithReleaseOrDraftProcessId &
  WithScanInstanceId &
  WithName;
export type TransformScanPageResult = unknown;
export type FinishScanningParams = WithReleaseOrDraftProcessId &
  WithScanInstanceId;
export type FinishScanningResult = ProcessInstanceDetails;
export type GetPageParams = WithReleaseOrDraftProcessId &
  WithScanInstanceId &
  WithName;
export type GetPageResult = Blob;
export type UpdatePageParams = UpdatePage &
  WithReleaseOrDraftProcessId &
  WithScanInstanceId &
  WithName;
export type UpdatePageResult = unknown;

const CONFIG_STOP_PATHS = ['mapper.dataMap', 'mapper.params'];

const ProcessScan = {
  deleteScanDocument: apiReqSagaCreator<
    DeleteScanDocumentParams,
    DeleteScanDocumentResult
  >(API_REQ_ACTION_TYPES.SCANNING.DELETE_BY_SCANNING_ID, (params) => ({
    method: HTTP_METHODS.DELETE,
    url: ApiPaths.processes.byId.scanning.instances.byId.documents.byId._(
      params
    ),
  })),
  updateScanDocument: apiReqSagaCreator<
    UpdateScanDocumentContainerParams,
    UpdateScanDocumentContainerResult
  >(API_REQ_ACTION_TYPES.SCANNING.UPDATE_BY_SCANNING_ID, (args) => ({
    method: HTTP_METHODS.PUT,
    url: ApiPaths.processes.byId.scanning.instances.byId.documents.byId._({
      [ApiUrlParams.scanDocContainerId]: args[ApiUrlParams.scanDocContainerId],
      [ApiUrlParams.releaseOrDraftProcessId]:
        args[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.scanInstanceId]: args[ApiUrlParams.scanInstanceId],
    }),
    data: args?.data,
  })),
  updateScanConfiguration: apiReqSagaCreator<
    UpdateScanConfigurationParams,
    UpdateScanConfigurationResult
  >(API_REQ_ACTION_TYPES.SCANNING_CONFIGURATION.UPDATE_BY_ID, (args) => ({
    method: HTTP_METHODS.PUT,
    url: ApiPaths.processes.byId.scanning.configurations.byId._({
      [ApiUrlParams.scanConfigurationId]:
        args[ApiUrlParams.scanConfigurationId],
      [ApiUrlParams.releaseOrDraftProcessId]:
        args[ApiUrlParams.releaseOrDraftProcessId],
    }),
    data: args.data,
    transformRequest: (data) => transformRequest(data, CONFIG_STOP_PATHS),
  })),
  deleteScanConfiguration: apiReqSagaCreator<
    DeleteScanConfigurationParams,
    DeleteScanConfigurationResult
  >(API_REQ_ACTION_TYPES.SCANNING_CONFIGURATION.DELETE_BY_ID, (params) => ({
    method: HTTP_METHODS.DELETE,
    url: ApiPaths.processes.byId.scanning.configurations.byId._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.scanConfigurationId]:
        params[ApiUrlParams.scanConfigurationId],
    }),
  })),
  bulkUpdateDocument: apiReqSagaCreator<
    BulkUpdateDocumentParams,
    BulkUpdateDocumentResult
  >(API_REQ_ACTION_TYPES.SCANNING.DOCUMENTS.BULK_UPDATE, (args) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.scanning.instances.byId.documents.bulk._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        args[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.scanInstanceId]: args[ApiUrlParams.scanInstanceId],
    }),
    data: args.data,
  })),
  deleteScanDocumentPage: apiReqSagaCreator<
    DeleteScanDocumentPageParams,
    DeleteScanDocumentPageResult
  >(API_REQ_ACTION_TYPES.SCANNING.DELETE_PAGE_BY_SCANNING_ID, (params) => ({
    method: HTTP_METHODS.DELETE,
    url: ApiPaths.processes.byId.scanning.instances.byId.pages.byName._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.scanInstanceId]: params[ApiUrlParams.scanInstanceId],
      [ApiUrlParams.name]: params[ApiUrlParams.name],
    }),
    headers: {
      'Content-Type': CONTENT_TYPE.MULTIPART_FORMDATA,
    },
    baseURL: documentServiceUrl,
  })),
  deleteScanDocumentPages: apiReqSagaCreator<
    DeleteScanDocumentPagesParams,
    DeleteScanDocumentPagesResult
  >(API_REQ_ACTION_TYPES.SCANNING.DELETE_PAGES_BY_SCANNING_ID, (args) => ({
    method: HTTP_METHODS.DELETE,
    url: ApiPaths.processes.byId.scanning.instances.byId.pages._({
      [ApiUrlParams.scanInstanceId]: args[ApiUrlParams.scanInstanceId],
      [ApiUrlParams.releaseOrDraftProcessId]:
        args[ApiUrlParams.releaseOrDraftProcessId],
    }),
    data: args.pages,
    headers: {
      'Content-Type': CONTENT_TYPE.MULTIPART_FORMDATA,
    },
    baseURL: documentServiceUrl,
    afterDocDelete: args.afterDocDelete,
  })),
  createScanConfiguration: apiReqSagaCreator<
    CreateScanConfigurationParams,
    CreateScanConfigurationResult
  >(API_REQ_ACTION_TYPES.SCANNING.CREATE, (args) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.scanning.configurations._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        args[ApiUrlParams.releaseOrDraftProcessId],
    }),
    data: args.data,
    transformRequest: (data) => transformRequest(data, CONFIG_STOP_PATHS),
  })),
  getAllScanConfigurations: apiReqSagaCreator<
    GetAllScanConfigurationsParams,
    GetAllScanConfigurationsResult
  >(API_REQ_ACTION_TYPES.SCANNING.GET_LIST, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.processes.byId.scanning.configurations._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
    }),
    params: { limit: params.limit, offset: params.offset },
  })),
  getScanConfigurationById: apiReqSagaCreator<
    GetScanConfigurationByIdParams,
    GetScanConfigurationByIdResult
  >(API_REQ_ACTION_TYPES.SCANNING.GET_BY_SCANNING_ID, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.processes.byId.scanning.configurations.byId._({
      [ApiUrlParams.scanConfigurationId]:
        params[ApiUrlParams.scanConfigurationId],
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
    }),
    transformResponse: (data) =>
      transformResponse(data, ['mapper.params', 'mapper.data_map']),
  })),
  createScanningInstance: apiReqSagaCreator<
    CreateScanningInstanceParams,
    CreateScanningInstanceResult
  >(API_REQ_ACTION_TYPES.SCANNING.CREATE_INSTANCE, (args) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.scanning.instances._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        args[ApiUrlParams.releaseOrDraftProcessId],
    }),
    data: args.data,
  })),
  saveScannedPage: apiReqSagaCreator<
    SaveScannedPageParams,
    SaveScannedPageResult
  >(API_REQ_ACTION_TYPES.SCANNING.PAGES.SAVE, (args) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.scanning.instances.byId.pages._({
      [ApiUrlParams.scanInstanceId]: args[ApiUrlParams.scanInstanceId],
      [ApiUrlParams.releaseOrDraftProcessId]:
        args[ApiUrlParams.releaseOrDraftProcessId],
    }),
    headers: {
      'Content-Type': CONTENT_TYPE.MULTIPART_FORMDATA,
    },
    baseURL: documentServiceUrl,
    data: args.data,
  })),
  uploadFileWhileExecute: apiReqSagaCreator<
    UploadFileWhileExecuteParams,
    UploadFileWhileExecuteResult
  >(API_REQ_ACTION_TYPES.PROCESSES.UPLOAD_FILE, (args) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.field.byScanInstanceId.byFieldId._({
      [ApiUrlParams.fieldId]: args[ApiUrlParams.fieldId],
      [ApiUrlParams.processInstanceId]: args[ApiUrlParams.processInstanceId],
    }),
    data: args.field,
    headers: {
      'Content-Type': CONTENT_TYPE.MULTIPART_FORMDATA,
    },
    baseURL: documentServiceUrl,
  })),
  getUploadedFileWhileExecute: apiReqSagaCreator<
    GetUploadedFileWhileExecuteParams,
    GetUploadedFileWhileExecuteResult
  >(API_REQ_ACTION_TYPES.PROCESSES.GET_UPLOADED_FILE, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.field.byScanInstanceId.byFieldId.byName._({
      [ApiUrlParams.name]: params[ApiUrlParams.name],
      [ApiUrlParams.fieldId]: params[ApiUrlParams.fieldId],
      [ApiUrlParams.processInstanceId]: params[ApiUrlParams.processInstanceId],
    }),
    responseType: RESPONSE_TYPE.BLOB,
    baseURL: documentServiceUrl,
  })),
  getUploadedFilePreviewWhileExecute: apiReqSagaCreator<
    GetUploadedFilePreviewWhileExecuteParams,
    GetUploadedFilePreviewWhileExecuteResult
  >(API_REQ_ACTION_TYPES.PROCESSES.GET_UPLOADED_FILE_PREVIEW, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.field.byScanInstanceId.byFieldId.byName.preview._({
      [ApiUrlParams.name]: params[ApiUrlParams.name],
      [ApiUrlParams.fieldId]: params[ApiUrlParams.fieldId],
      [ApiUrlParams.processInstanceId]: params[ApiUrlParams.processInstanceId],
    }),
    responseType: RESPONSE_TYPE.BLOB,
    baseURL: documentServiceUrl,
  })),
  createDocument: apiReqSagaCreator<CreateDocumentParams, CreateDocumentResult>(
    API_REQ_ACTION_TYPES.SCANNING.DOCUMENTS.CREATE,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.scanning.instances.byId.documents._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.scanInstanceId]: args[ApiUrlParams.scanInstanceId],
      }),
      data: args.data,
    })
  ),
  updateDocument: apiReqSagaCreator<UpdateDocumentParams, UpdateDocumentResult>(
    API_REQ_ACTION_TYPES.SCANNING.DOCUMENTS.UPDATE,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId.scanning.instances.byId.documents.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.scanInstanceId]: args[ApiUrlParams.scanInstanceId],
        [ApiUrlParams.scanDocContainerId]:
          args[ApiUrlParams.scanDocContainerId],
      }),
      data: args.data,
    })
  ),
  getDocumentById: apiReqSagaCreator<
    GetDocumentByIdParams,
    GetDocumentByIdResult
  >(API_REQ_ACTION_TYPES.SCANNING.DOCUMENTS.GET_DOCUMENT, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.processes.byId.scanning.instances.byId.documents.byId._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.scanDocContainerId]:
        params[ApiUrlParams.scanDocContainerId],
      [ApiUrlParams.scanInstanceId]: params[ApiUrlParams.scanInstanceId],
    }),
  })),
  getListDocuments: apiReqSagaCreator<
    GetListDocumentsParams,
    GetListDocumentsResult
  >(
    API_REQ_ACTION_TYPES.SCANNING.DOCUMENTS.GET_LIST,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.scanning.instances.byId.documents._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.scanInstanceId]: params[ApiUrlParams.scanInstanceId],
      }),
      params: {
        limit: params.limit,
        offset: params.offset,
      },
    }),
    {
      withPrevPageOnLessTotal: true,
    }
  ),
  getAllDocumentsByName: apiReqSagaCreator<
    GetAllDocumentsByNameParams,
    GetAllDocumentsByNameResult
  >(API_REQ_ACTION_TYPES.SCANNING.DOCUMENTS.GET_ALL_LIST, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.processes.byId.scanning.instances.byId.documents._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.scanInstanceId]: params[ApiUrlParams.scanInstanceId],
    }),
    params: {
      name: params.name,
      limit: params.limit,
      offset: params.offset,
    },
  })),
  getPagesPreview: apiReqSagaCreator<
    GetPagesPreviewParams,
    GetPagesPreviewResult
  >(API_REQ_ACTION_TYPES.SCANNING.PAGES.GET_PAGES_PREVIEW, (args) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.scanning.instances.byId.pages.preview._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        args[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.scanInstanceId]: args[ApiUrlParams.scanInstanceId],
    }),
    responseType: RESPONSE_TYPE.ARRAY_BUFFER,
    baseURL: documentServiceUrl,
    data: args.data,
  })),
  transformScanPage: apiReqSagaCreator<
    TransformScanPageParams,
    TransformScanPageResult
  >(API_REQ_ACTION_TYPES.SCANNING.PAGES.TRANSFORM_PAGE, (args) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.scanning.instances.byId.pages.byName.transform._(
      {
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.scanInstanceId]: args[ApiUrlParams.scanInstanceId],
        [ApiUrlParams.name]: args[ApiUrlParams.name],
      }
    ),
    baseURL: documentServiceUrl,
    data: args.data,
  })),
  finishScanning: apiReqSagaCreator<FinishScanningParams, FinishScanningResult>(
    API_REQ_ACTION_TYPES.SCANNING.FINISH_SCANNING,
    (params) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.scanning.instances.byId.finish._({
        [ApiUrlParams.scanInstanceId]: params[ApiUrlParams.scanInstanceId],
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
    })
  ),
  getPage: apiReqSagaCreator<GetPageParams, GetPageResult>(
    API_REQ_ACTION_TYPES.SCANNING.PAGES.GET_PAGE,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.scanning.instances.byId.pages.byName._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.scanInstanceId]: params[ApiUrlParams.scanInstanceId],
        [ApiUrlParams.name]: params[ApiUrlParams.name],
      }),
      responseType: RESPONSE_TYPE.BLOB,
      baseURL: documentServiceUrl,
    })
  ),
  updatePage: apiReqSagaCreator<UpdatePageParams, UpdatePageResult>(
    API_REQ_ACTION_TYPES.SCANNING.PAGES.UPDATE_PAGE,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId.scanning.instances.byId.pages.byName._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.scanInstanceId]: args[ApiUrlParams.scanInstanceId],
        [ApiUrlParams.name]: args[ApiUrlParams.name],
      }),
      baseURL: documentServiceUrl,
      data: args.page,
    })
  ),
};

export default ProcessScan;
